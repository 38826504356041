/** @jsx jsx */
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'
import StartpageLogo from '../../images/startpage-logo.svg'
import {colors} from '../../constants'

const Header404Page = ({homepageUrl, links}) => {
    return (
        <header
            css={css`
                padding: 1rem 2rem 1rem 1rem;
                background-color: #fafaff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 2px solid ${colors.brandBlue};
                @media (max-width: 950px) {
                    flex-direction: column;
                    align-items: center;
                }
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <a
                    href={homepageUrl}
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                    aria-label="go to startpage.com homepage"
                >
                    <StartpageLogo
                        css={css`
                            height: 40px;
                            width: 203px;
                            @media (max-width: 950px) {
                                display: block;
                                margin: 0 auto 1rem;
                            }
                        `}
                    />
                </a>
            </div>
            <div
                className="header-links"
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <div
                    css={css`
                        margin-left: 1rem;
                        @media (max-width: 950px) {
                            display: none;
                        }
                    `}
                >
                    {links.map((linkInfo, i) => {
                        const {text, link} = linkInfo
                        return (
                            <a
                                css={css`
                                    text-decoration: none;
                                    margin: 1rem;
                                    font-size: 1rem;
                                    color: #3b435c;
                                    &:hover {
                                        color: ${colors.brandBlue};
                                        cursor: pointer;
                                    }
                                `}
                                key={i}
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={text}
                            >
                                {text}
                            </a>
                        )
                    })}
                </div>
            </div>
        </header>
    )
}

Header404Page.propTypes = {
    homepageUrl: PropTypes.string,
    links: PropTypes.array,
}

Header404Page.defaultProps = {
    homepageUrl: `https://www.startpage.com`,
    links: [
        {
            text: `Settings`,
            link: `https://www.startpage.com/search/settings?lang=en`,
        },
        {
            text: `Support`,
            link: `https://support.startpage.com/`,
        },
        {text: `StartMail`, link: `https://www.startmail.com/en/startpage/?pk_campaign=startpage`},
    ],
}

export default Header404Page
