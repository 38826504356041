/** @jsx jsx */
import {jsx, css} from '@emotion/react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Header404Page from '../components/404/header'
import ButtonInstall from '../components/button-install'

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <Header404Page />
        <div
            css={css`
                padding: 1rem;
                max-width: 500px;
            `}
        >
            <div>
                <h1>404</h1>
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
            <div
                css={css`
                    margin: 2rem 0;
                `}
            >
                <p>Looking to install the Startpage extension?</p>
                <ButtonInstall />
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
