/** @jsx jsx */
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import {jsx, css} from '@emotion/react'
import {colors} from '../constants'

const ButtonInstall = ({text}) => (
    <Link to="/">
        <button
            className="button-install"
            css={css`
                box-shadow: none;
                border: 2px solid ${colors.whisper};
                border-radius: 4px;
                color: ${colors.whisper};
                background-color: ${colors.brandBlue};
                padding: 7px 14px;
                transition: all 0.3s ease;
                font-size: 14px;
                font-weight: 500;

                &:hover {
                    border-color: transparent;
                    background: ${colors.whisper};
                    color: ${colors.brandBlue};
                    cursor: pointer;
                }
            `}
        >
            {text}
        </button>
    </Link>
)

ButtonInstall.propTypes = {
    text: PropTypes.string,
}

ButtonInstall.defaultProps = {
    text: `Install Startpage Extension`,
}

export default ButtonInstall
